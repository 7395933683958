@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");


html {
  zoom: "90%"
}


div,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Poppins", sans-serif;
  font-size: "12px";
}

a:-webkit-any-link {
  text-decoration: none;
  color: black;
  font-family: "Roboto", "Poppins", sans-serif;
}

input:focus,
textarea:focus,
select:focus {
  outline: none !important;
  border: 0;
}

.template-container span,
.template-container font {
  font-family: "EB Garamond", sans-serif !important;
}

.template-container font {
  font-family: "EB Garamond", sans-serif !important;
}

.navbar-brand > img {
  width: 60px;
  height: 40px;
}

.nav-links {
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 5px;
}
.nav-links:hover {
  border-bottom: 2px solid #0f0d8d;
}
.active-link {
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 5px;
  border-bottom: 2px solid #0f0d8d;
}

#cart-count {
  background-color: #0f0d8d;
  border-radius: 50%;
  color: white;
  width: 15px;
  height: 15px;
  align-content: center;
  display: flex;
  justify-content: center;
  font-size: 10px;
}

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
}

.form-input,
.form-input:focus {
  background-color: inherit;
  outline: 0;
  border-width: 0 0 2px;
  border-color: #0b2594;
  width: 85%;
}

.flex {
  font-size: 16px;
  font-weight: 700;
  padding-top: 23px;
}
.flex.lg\:justify-start.md\:justify-between.items-center.lg\:pl-28.pl-12.justify-around.mb-5 {
  padding-left: 15rem;
}
@media (min-width: 1024px) {
  .lg\:gap-28 {
    gap: 2rem !important;
  }
  .lg\:gap-28 {
    gap: 4rem !important;
  }

  .lg\:gap-5 {
    gap: 2rem !important;
  }
}
